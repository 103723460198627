import {
  Component, Input, OnDestroy, OnInit,
} from '@angular/core';
import { UpdateMyCourseRequestComponent } from './components/update-my-course-request/update-my-course-request.component';
import { MatDialog } from '@angular/material/dialog';
import { CourseRequestsService } from 'src/app/services/course-requests.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MyCourseRequestDetailsComponent } from './components/my-course-request-details/my-course-request-details.component';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { ToastService } from 'src/app/services/toast.service';
import {
  NewTableData, TableBurgerClickEvent, TableRow, TableRowClickEvent, TableSortEvent,
} from 'src/app/components/new-table/new-table.component';
import { formatDate, formatTime } from 'src/app/routes/manage-courses/components/course-catalog/components/update-course/course.class';
import { GeneralDataCell, StatusDataCell } from 'src/app/components/new-table/table-cell/table-cell.component';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

@Component({
  selector: 'app-my-course-requests',
  templateUrl: './my-course-requests.component.html',
  styleUrls: [ './my-course-requests.component.scss' ],
})
export class MyCourseRequestsComponent implements OnInit, OnDestroy {
  _coursesData: any;
  coursesData$: Observable<any>;
  tableData: any;
  loading = false;

  searchQuery = '';
  offset = 0;
  selectedItemsPerPage = 25;
  sortColumn = 'courseName';
  sortDirection = 'ASC';

  // #region Subscription

  courseDataSubscription: Subscription;
  // #endregion Subscription

  // #region new Table setup
  newTableData: NewTableData = {
    title: 'Course Requests',
    rowStyling: 'default',
    noDataFoundMessage: 'No course requests found',
    noDataButtonText: 'Submit Course Request',
    columnTitles: [
      {
        name: 'Course Name & ID',
        align: 'left',
        sortable: true,
        sortByProp: 'courseName',
      },
      {
        name: 'Date & Time',
        align: 'left',
        sortable: true,
        sortByProp: 'dateOffered',
      },
      {
        name: 'Request Date',
        align: 'left',
        sortable: true,
        isDefaultSort: true,
        sortByProp: 'courseCreatedDate',
        sortDirection: 'ASC',
      },
      {
        name: 'Status',
        align: 'left',
        sortable: true,
        sortByProp: 'currentCatalogStatusKid',
      },
    ],
    meta: {
      usePagination: true,
      totalItems: 0,
      curPage: 1,
      totalPages: 1,
      itemsPerPage: 25,
      links: {
        first: '',
        last: '',
        next: '',
        prev: '',
        self: '',
      },
    },
    data: [],
    hasBurgerMenu: true,
    burgerContent: [
      {
        content: 'View Course Request Details',
        eventName: 'viewRequest',
      },
      {
        content: 'Remove Course Request',
        eventName: 'removeRequest',
      },
    ],
  }

  // #endregion new Table setup

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private courseRequestService: CourseRequestsService,
    private loadingService: LoadingSpinnerService,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.searchForMyCourseRequests();

    this.courseDataSubscription = this.courseRequestService.courseRequestsData
      .subscribe((data: any) => {
        if (!data) return;

        this._coursesData = data;

        const rows = this.formatTableData(data);
        this.newTableData.data = rows;
        this.newTableData.meta = this.formatTableMeta(data);

        this.loading = false;
      })
  }

  searchForMyCourseRequests = () => {
    this.loading = true;
    this.newTableData.data = []

    const params = {
      limit: this.selectedItemsPerPage,
      offset: this.offset,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      includedAssociations: 'User,Course',
      activeFlag: 1,
      filterString: this.searchQuery,
    }

    this?.route?.snapshot?.params['id'] ?
      this.courseRequestService.getUserCourseRequests(this?.route?.snapshot?.params['id'], params) :
      this.courseRequestService.getUserCourseRequests(null, params)
  }

  // #region Table Formatting

  formatDateCell = (row: any): GeneralDataCell => {
    const preRecordedOption = row.preRecordedOption;

    const isAsync = preRecordedOption === 'ASYNC';
    const isAnytime = preRecordedOption === 'ANYTIME';

    if (isAsync) {
      return {
        mainText: 'Async',
        subText: 'Async',
      }
    }

    if (isAnytime) {
      return {
        mainText: 'Anytime',
        subText: 'Anytime',
      }
    }

    return {
      mainText: formatDate(row.dateOffered, true),
      subText: `${formatTime(row.sessionStartDate)} - ${formatTime(row.sessionEndDate)}`,
    }
  }

  formatStatus = (statusKid: string) => {
    const splitString = statusKid.split('SPECIAL_REQUEST_');
    const status = splitString[splitString.length - 1];
    const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

    return capitalizedStatus;
  }

  formatSingleRow = (row: any) => {
    const courseInfoCell: GeneralDataCell = {
      mainText: row.courseName,
      subText: row.courseItemId,
    };

    const dateAndTimeCell: GeneralDataCell = this.formatDateCell(row);

    const requestDateCell: GeneralDataCell = {
      mainText: formatDate(row.courseCreatedDate, true),
      mainFontStyle: 'normal',
    };

    const statusCell: StatusDataCell = {
      statusDisplay: this.formatStatus(row.currentCatalogStatusKid),
      statusKid: row.currentCatalogStatusKid,
      statusType: 'courseCatalog',
      forceUppercase: false,
    };

    const rowCells = [
      courseInfoCell,
      dateAndTimeCell,
      requestDateCell,
      statusCell,
    ];

    const formattedRow: TableRow = {
      itemId: Number(row.id),
      columnData: rowCells,
    };

    return formattedRow;
  }

  formatTableData = (data: any) => {
    const rows = data.rows;

    const formattedData = rows.map(this.formatSingleRow);

    return formattedData;
  }

  formatTableMeta = (data: any): NewTableData['meta'] => {
    const pagination = data.pagination;
    const links = data.links;

    return {
      curPage: pagination.curPage,
      itemsPerPage: pagination.totalPerPage,
      totalItems: pagination.totalItems,
      totalPages: pagination.totalPages,
      usePagination: true,
      links: {
        first: links.first,
        last: links.last,
        next: links.next,
        prev: links.prev,
        self: links.self,
      },
    }
  }

  // #endregion Table Formatting

  // #region Event Handlers
  searchQueryChanged = (searchQuery: string) => {
    this.searchQuery = searchQuery;
    this.offset = 0;
    this.searchForMyCourseRequests();
  }

  handleRowClick = (id: TableRowClickEvent) => {
    this.showItemDetails(id);
  }

  handleMeatballClick = (event: TableBurgerClickEvent) => {
    console.log('handleMeatballClick', event);
    if (event.eventName === 'viewRequest') {
      this.showItemDetails(event.itemId);
    }

    if (event.eventName === 'removeRequest') {
      const itemToRemove = { itemId: event.itemId };
      this.removeItem(itemToRemove);
    }
  }

  handleSortEvent = (event: TableSortEvent) => {
    this.sortColumn = event.columnName;
    this.sortDirection = event.sortDirection;

    this.searchForMyCourseRequests();
  }

  openCreateModal = () => { }
  // #endregion Table Click Handlers

  // #region Filter, Sort and Pagination Handling
  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForMyCourseRequests();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForMyCourseRequests();
  }

  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  // #endregion Filter, Sort and Pagination Handling

  toggleCreateModal = () => {
    this.dialog.open(UpdateMyCourseRequestComponent).afterClosed().subscribe((result) => {
      if (result.statusCode === 1000) {
        this.toast.setToast({
          text: 'Course request submitted successfully',
          type: 'success',
          dismissible: false,
          icon: true,
        })
        this.searchForMyCourseRequests();
      }
    })
  }

  showItemDetails = (item: number) => {
    const selectedCourse = this._coursesData?.rows?.find((course: any) => course.id == item);

    const dialogRef = this.dialog.open(MyCourseRequestDetailsComponent, { data: selectedCourse })
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancel-request') {
        const itemToRemove = { itemId: selectedCourse.id };
        this.removeItem(itemToRemove);
      }
    })
  }

  removeItem = (item: any) => {
    const selectedCourse = this._coursesData?.rows?.find((course: any) => course.id == item.itemId);
    const verificationRef = this.dialog.open(VerificationComponent, {
      data: {
        type: 'alert',
        title: 'Are you sure?',
        confirmButtonTitle: 'Yes, Delete Request',
        cancelButtonTitle: 'Cancel',
        text: 'Are you sure you want to delete this course request? This action cannot be undone.',
      },
    })
    verificationRef.afterClosed().subscribe((result) => {
      if (result === 'verified') {
        this.loadingService.setIsLoading(true);

        this.courseRequestService.removeCourseRequest(selectedCourse.courseId)
          .subscribe({
            next: () => {
              this.toast.setToast({
                text: 'Course request removed successfully',
                type: 'success',
                dismissible: false,
                icon: true,
              })
              this.loadingService.setIsLoading(false);
              this.searchForMyCourseRequests();
            },
            error: (error) => {
              this.toast.setToast({
                text: 'There was an error removing the course',
                type: 'error',
                dismissible: false,
                icon: true,
              })
              this.loadingService.setIsLoading(false);
              this.searchForMyCourseRequests();

              throw error;
            },
          })
      }
    })
  }

  ngOnDestroy(): void {
    this.courseDataSubscription.unsubscribe();
  }

}
