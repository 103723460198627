export const environment = {
  expressUrl: 'https://app-api.kalpasolutions.com',
  production: true,
  amplify: {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_JUJ5Vwqrb',
      userPoolWebClientId: 'aicsii98i6bh3sqks5bef7cpp',
      identityPoolId: 'us-east-2:23d7073a-132b-44de-ac63-e2981951ae47',
    },
  },
  googleClientId: '903559517820-3jt9mvbfh07aku5g53biira8vimsh2lh.apps.googleusercontent.com',
};

// TODO - change the expressUrl to the production URL
